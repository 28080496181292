import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {Router, Route, Switch, BrowserRouter} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import HomePage from "./views/HomePage/HomePage";
import ContactPage from "./views/ContactPage/ContactPage";

// var hist = createBrowserHistory();

ReactDOM.render(

        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Route exact path={'/landing-page'} component={LandingPage} />
            <Route exact path={"/profile-page"} component={ProfilePage} />
            <Route exact path={"/contact-us"} component={ContactPage} />
            <Route exact path={"/login-page"} component={LoginPage} />
            <Route exact path={"/all"} component={Components} />
            <Route exact path={"/"} component={HomePage} />
        </BrowserRouter>,
    document.getElementById("root")
);
